<template>
    <UpdateClinic :id="$route.params.id"/>
</template>
<script>
import UpdateClinic from "@/components/admin/modules/gest-clinic/UpdateClinic";

export default {
    name: "UpdateClinicView",
    title: "Actualizar Clínica | Baja California Health Tourism",
    components: {
        UpdateClinic
    }
}
</script>